export interface OrderCustomer {
    name: string
    address: string
    lat: string
    lng: string
    note: string
}

export enum OrderSegment {
    RESTAURANT = 'restaurant',
    ESHOP = 'eshop',
    DIET = 'diet',
}

export interface OrderBranch {
    name: string
    lat: string
    lng: string
    color: string
    logo_src: string
    segment: OrderSegment
    phone_dispatching: string
}

export interface OrderDriver {
    phone: string
}

export enum OrderState {
    NEW = 'new',
    WAITING = 'waiting_for_handover',
    PICKUP = 'pickup',
    DELIVERING = 'delivering',
    DELIVERED = 'delivered',
    UNDELIVERED = 'undelivered',
    ARRIVED = 'arrived',
    CANCELED = 'canceled'
}

export const StopStates: OrderState[] = [OrderState.DELIVERED, OrderState.CANCELED, OrderState.UNDELIVERED, OrderState.ARRIVED]

export interface OrderEvaluation {
    message: string
    evaluation: 5
}

export interface OrderData {
    id: string
    code: string
    customer: OrderCustomer
    branch: OrderBranch
    state: OrderState
    driver: OrderDriver | null
    evaluation: OrderEvaluation | null
    evaluation_enabled: boolean
    delivery_timestamp: number
    delivery_since_timestamp: number
    customer_eta_timestamp: number | null
    extended_delivery_minutes: number | null
}

export interface Order {
    success: boolean
    data: OrderData
}
